// + de paramètres possible sur https://swiperjs.com/demos et sur https://swiperjs.com/swiper-api

var swiper = new Swiper(".diaporama", {
    hashNavigation: {
        watchState: true,
    },
    pagination: {
        el: ".diaporama-pagination",
        clickable: true,
    },
    bulletClass: 'swiper-pagination-bullet diaporama-bullet',
    bulletActiveClass: 'swiper-pagination-bullet-active diaporama-bullet--active'
});



var ficheProduitSwiper = new Swiper(".ficheProduit-swiper", {
    // allowTouchMove: false,
    hashNavigation: {
        watchState: true,
    },
    pagination: {
        el: ".ficheProduit-pagination",
        clickable: true,
    },
    bulletClass: 'swiper-pagination-bullet ficheProduit-bullet',
    bulletActiveClass: 'swiper-pagination-bullet-active ficheProduit-bullet--active',
});

$(document).on('click', '.ficheProduit-buttonPrev', function() {
    if (ficheProduitSwiper.isBeginning === false) {
        ficheProduitSwiper.slidePrev();
    }
});

$(document).on('click', '.ficheProduit-buttonNext', function() {
    if (ficheProduitSwiper.isEnd === false) {
        ficheProduitSwiper.slideNext();
    }
});
